<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import SliderControl from "@/components/additional/SliderControl.vue";
import SlideCard from "@/components/additional/SlideCard.vue";
import 'swiper/css';

const slides = ref([
  {
    text: "Digital platforms allow you to reach audiences across the globe, breaking geographical barriers.",
    title: "Reach",
    image: "slide1.jpg",
  },
  {
    text: "Engage your audience in real-time, fostering stronger connections and building brand loyalty.",
    title: "Engagement",
    image: "slide2.jpg",
  },
  {
    text: "Measure every action, understand your audience's behavior, and optimize for better results. The digital realm offers unparalleled insights into your campaigns.",
    title: "Analytics",
    image: "slide3.jpg",
  },
  {
    text: "With targeted campaigns, get more bang for your buck. Efficiently allocate resources to where they matter the most.",
    title: "Cost-Efficient",
    image: "slide4.jpg",
  }
])

const swiperInstance = ref(null);
const onSwiper = (swiper) => {
  swiperInstance.value = swiper;
};

const goNext = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slideNext();
  }
};

const goPrev = () => {
  if (swiperInstance.value) {
    swiperInstance.value.slidePrev();
  }
};
</script>

<template>
  <div
    class="slider"
    v-motion
    :initial="{opacity: 0, x: 30}"
    :visibleOnce="{opacity: 1, x: 0, transition: { duration: 1000, delay: 500 }}"
  >
    <swiper
        class="slider"
        ref="swiperRef"
        slides-per-view="auto"
        :space-between="32"
        @swiper="onSwiper"
        :modules="[Navigation]"
    >
      <swiper-slide v-for="(slide, id) in slides" :key="id">
        <SlideCard
          :text="slide.text"
          :image="slide.image"
          :title="slide.title"
        />
      </swiper-slide>
    </swiper>
    <div class="slider-control flex">
      <SliderControl
          @click="goPrev"
          :disabled="swiperInstance?.isBeginning"
      ></SliderControl>
      <SliderControl
          @click="goNext"
          btnType="next"
          :disabled="swiperInstance?.isEnd"
      ></SliderControl>
    </div>
  </div>
</template>

<style lang="scss">
.slider {
  .swiper {
    overflow: visible;
    .swiper-slide {
      width: auto !important;
      height: auto;
      max-width: 352px;
    }
  }
  &-control {
    margin: 40px 0 0 0;
    justify-content: flex-start;
    gap: 10px;
  }
}

@media (max-width: 460px) {
  .slider {
    .swiper .swiper-slide {
      width: 100% !important;
      height: auto;
      max-width: 100%;
    }
    &-control {
      margin: 22px 0 0 0;
      justify-content: flex-start;
    }
  }
}
</style>