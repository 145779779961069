<script setup>
import { ref, watch, onUnmounted } from "vue";
import Input from "@/components/ui/Input.vue";
import Button from "@/components/ui/Button.vue";

const emit = defineEmits(['response']);

const props = defineProps({
  inModal: Boolean
})

const data = ref({
  name: "",
  email: "",
  message: "",
  subject: ""
})

const isChecked = ref(false);
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

watch(isSent, () => {
  if(!isSent.value) resetForm();
});

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key === 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: "",
  }
  isSent.value = false;
  isChecked.value = false;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
      .then(response => {
        isSent.value = true;
        isSuccess.value = true;
        emit('response', true);
        resetForm();
      })
      .catch(e => {
        isSent.value = true;
        isSuccess.value = false;
        emit('response', false);
      })
}

const send = () => {
  isError.value = validate();
  if(!isError.value) {
    const formdata = new FormData();
    formdata.append('id', 'Beta Marketing');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "info@beta-solutions.net");
    formdata.append('message', 'Message: ' + data.value.message);
    formdata.append('subject', 'Beta Marketing Website Request: ' + data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
});

</script>

<template>
  <div class="f-form">
    <div class="f-form__top flex flex-col">
      <h4
        v-motion
        :initial="inModal ? { opacity: 1 } : { opacity: 0 }"
        :visibleOnce="inModal ? { opacity: 1 } : { opacity: 1, transition: { duration: 1000, delay: 0 } }"
      ><span>Any questions?</span><br/><span>Send a mail to our team :)</span></h4>
    </div>
    <form
      class="flex flex-col"
      id="formm"
      @submit.prevent="send"
      v-motion
      :initial="inModal ? { opacity: 1 } : { opacity: 0, y: -40 }"
      :visibleOnce="inModal ? { opacity: 1 } : { opacity: 1, y: 0, transition: { duration: 1200, delay: 0 } }"
    >
      <div class="inputs flex">
        <Input
          :placeholder="inModal ? 'Name*' : 'Your name'"
          type="text"
          v-model="data.name"
          :isError="isError && data.name.toString().trim().length == 0"
          :inModal="inModal"
        />
        <Input
          :placeholder="inModal ? 'Email*' : 'Your email'"
          type="email"
          v-model="data.email"
          :isError="isError && !emailRegex.test(data.email)"
          :inModal="inModal"
        />
      </div>
      <Input
          placeholder="Subject"
          type="text"
          v-model="data.subject"
          :inModal="inModal"
      />
      <Input
          :placeholder="inModal ? 'Message' : 'Your message'"
          type="text"
          textarea
          v-model="data.message"
          :inModal="inModal"
      />
      <div class="flex bottom justify-between align-center">
        <label class="custom-checkbox flex align-center" :class="{'checked': isChecked}">
          <input type="checkbox" id="ch1" name="check" v-model="isChecked" required/>
          <span class="checkmark" :class="{'modal': inModal}"></span>
          <span class="check-text transparency-text second" :class="{'modal': inModal}">
            I have read and accept the <RouterLink class="underline" to="/privacy">Privacy Policy</RouterLink>.
          </span>
        </label>
        <Button
          primary
          type="submit"
        >Send a request</Button>
      </div>
    </form>
  </div>

</template>

<style lang="scss">
.f-form {
  width: 100%;
  max-width: 810px;
  margin: 64px auto 0;
  .inputs {
    gap: 14px;
    .input {
      width: 100%;
    }
  }
  &__top {
    color: #fff;
    gap: 20px;
    h4 {
      color: #1D1D1F;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
      line-height: 42px;
      span:nth-child(3) {
        color: #DD168B;
        font-weight: 700;
      }
    }
  }
  .bottom {
    gap: 32px;
    margin-top: 12px;
    .underline {
      text-decoration: underline;
    }
  }
}
form {
  margin-top: 58px;
  gap: 20px;
  button {
    max-width: 50% !important;
    height: 62px;
  }
}
.custom-checkbox {
  position: relative;
  cursor: pointer;
  align-items: flex-start !important;
  transition: all .2s ease;
  &:hover {
    .checkmark {
      border: 1px solid rgba(255, 188, 227, 0.6) !important;
    }
    .check-text, .check-text a {
      color: rgba(255, 188, 227, 0.6) !important;
    }
  }
  &.checked:hover {
    opacity: 0.7;
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  input[type="checkbox"]:checked + .checkmark {
    border: 1px solid #DD168B !important;
    background-size: 50% 50%;
    border-radius: 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/img/check.svg");
    & + .check-text {
      color: #DD168B !important;
      a {
        color: #DD168B !important;
      }
    }
  }
  .check-text {
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
    color: #CCC;
    transition: color .3s ease;
    &.modal {
      color: rgba(238, 224, 232, 0.80);
    }
    a {
      color: #CCC;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      transition: color .3s ease;
    }
  }
  .checkmark {
    display: inline-block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    background: none;
    cursor: pointer;
    transition: border .3s ease;
    &.modal {
      border: 1px solid rgba(210, 210, 215, 0.34);
    }
  }
}

@media (max-width: 768px) {
  .f-form {
    margin: 50px auto 0;
    &__top {
      h4 {
        text-align: left;
        font-size: 26px;
        line-height: 40px;
      }
    }
    .inputs {
      gap: 11px;
      flex-direction: column;
    }
    .bottom {
      flex-direction: column;
      gap: 32px;
      margin-top: 6px;
      align-items: flex-start;
      button {
        max-width: 100% !important;
      }
    }
  }
  form {
    gap: 11px;
    margin-top: 26px;
  }
}
</style>