<script setup>
import { ref } from "vue";

const props = defineProps({
  modelValue: String,
  textarea: Boolean,
  placeholder: String,
  isError: Boolean,
  required: Boolean,
  inModal: Boolean,
  type: String
})
const emit = defineEmits(['update:modelValue']);

const isTyping = ref(false);

const handleFocus = () => {
  isTyping.value = true;
};

const updateModelValue = (value) => {
  isTyping.value = true;
  emit('update:modelValue', value);
};
</script>

<template>
  <div class="input" :class="{'error': isError, 'filled': modelValue !== '' && !isTyping, 'modal': inModal}">
    <span class="placeholder" :class="{'filled': modelValue !== ''}">{{ placeholder }}</span>
    <input
        v-if="!textarea"
        :type="type"
        :value="modelValue"
        @input="updateModelValue($event.target.value)"
        @focus="handleFocus"
        @blur="isTyping = false"
        :required="required"
    />
    <textarea
        v-else
        :value="modelValue"
        @input="updateModelValue($event.target.value)"
        @focus="handleFocus"
        @blur="isTyping = false"
    ></textarea>
    <svg class="error-icon" v-if="isError" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM8 3C8.19891 3 8.38968 3.07902 8.53033 3.21967C8.67098 3.36032 8.75 3.55109 8.75 3.75V8.25C8.75 8.44891 8.67098 8.63968 8.53033 8.78033C8.38968 8.92098 8.19891 9 8 9C7.80109 9 7.61032 8.92098 7.46967 8.78033C7.32902 8.63968 7.25 8.44891 7.25 8.25V3.75C7.25 3.55109 7.32902 3.36032 7.46967 3.21967C7.61032 3.07902 7.80109 3 8 3ZM8 13C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13Z" fill="#DD165E"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.input {
  border-radius: 12px;
  border: 1px solid #D9D9D9;
  padding: 8px 14px;
  position: relative;
  transition: all .3s ease;
  &.modal {
    border: 1px solid rgba(238, 224, 232, 0.34);
    .placeholder {
      color: rgba(238, 224, 232, 0.40);
    }
    input, textarea {
      color: #fff;
    }
    &:hover, &:focus {
      border: 1px solid #DD168B;
    }
  }
  &.error {
    border: 1px solid rgba(221, 22, 94, 0.75);
    .placeholder {
      color: #DD165E !important;
    }
    .error-icon {
      display: block;
    }
  }
  &:hover {
    border: 1px solid rgba(221, 22, 139, 0.21);
  }
  &:focus {
    border: 1px solid rgba(221, 22, 139, 0.31);
    .placeholder {
      color: #DD168B;
    }
  }
  &.filled {
    .placeholder {
      color: #DD168B;
    }
  }
  .error-icon {
    position: absolute;
    top: 25px;
    right: 14px;
    display: none;
  }
  .placeholder {
    position: absolute;
    top: 10px;
    left: 14px;
    color: rgba(124, 124, 124, 0.4);
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    transition: all .2s ease;
    &.filled {
      color: #DD168B;
    }
  }
  input,
  textarea {
    border: none;
    outline: none;
    background: none;
    color: #1D1D1F;
    font-size: 16px;
    line-height: 28px;
    padding-top: 16px;
    caret-color: #DD168B;
    width: 100%;
    height: 50px;
  }
  textarea {
    resize: none;
    min-height: 100px;
  }
}

@media (max-width: 768px) {
  .input {
    input, textarea {
      font-size: 16px;
    }
  }
}
</style>