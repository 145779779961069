<script setup>
</script>

<template>
  <div class="marquee-container">
    <div class="marquee-content flex align-center">
      <img src="@/assets/img/name.png" alt="company" />
      <p>BETA MARKETING</p>
      <img src="@/assets/img/name.png" alt="company" />
      <p>BETA MARKETING</p>
      <img src="@/assets/img/name.png" alt="company" />
      <p>BETA MARKETING</p>
      <img src="@/assets/img/name.png" alt="company" />
      <p>BETA MARKETING</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.marquee-container {
  width: 100%;
  white-space: nowrap;
  padding: 60px 0 40px;
}

.marquee-content {
  gap: 38px;
  animation: marquee 100s linear infinite;
  img {
    width: 780px;
    height: 65px;
  }
  p {
    color: #ECECED;
    font-size: 86px;
    font-weight: 900;
    line-height: 86px;
    text-transform: uppercase;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-500%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-700%);
  }
}

@media (max-width: 768px) {
  .marquee-container {
    padding: 32px 0;
  }
  .marquee-content {
    gap: 22px;
    animation: marquee2 90s linear infinite;
    img {
      width: 430px;
      height: 36px;
    }
    p {
      font-size: 48px;
      line-height: 48px;
    }
  }
}
</style>