<template>
  <RouterLink to="/" class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="39" viewBox="0 0 88 39" fill="none">
      <path d="M15.0107 36.5574L16.1677 34.1025H17.2622V38.0897H16.4023V35.6192L15.2139 38.0897H14.8074L13.619 35.6192V38.0897H12.759V34.1025H13.8536L15.0107 36.5574Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1421 37.1359H22.0625C21.9218 37.433 21.7655 37.7458 21.6091 38.0897H20.624L22.5629 34.1025H23.6418L25.5807 38.0897H24.5956L24.1421 37.1359ZM23.032 35.0876C22.9851 35.1502 22.7818 35.6036 22.4691 36.2916H23.7512L23.1727 35.0876H23.032Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.0696 36.3854L33.1329 38.0897H32.0696L31.1315 36.4949H29.7555V38.0897H28.8799V34.1025H31.4911C32.398 34.1025 32.9609 34.6029 32.9609 35.2909C32.9609 35.8538 32.6013 36.276 32.0696 36.3854ZM31.3504 35.7443C31.71 35.7443 31.882 35.5567 31.882 35.3378C31.882 35.1032 31.71 34.9469 31.366 34.9469L29.7555 34.9782V35.7287L31.3504 35.7443Z" fill="white"/>
      <path d="M38.9183 35.7131L40.8728 38.0897H39.8252L38.3085 36.229L37.4954 36.9327V38.0897H36.6354V34.1025H37.4954V36.0258L39.747 34.1025H40.7946L38.9183 35.7131Z" fill="white"/>
      <path d="M48.0341 38.0897H44.2502V34.1025H47.9247V34.9469H45.1102V35.588H47.4243V36.4792H45.1102V37.2454H48.0341V38.0897Z" fill="white"/>
      <path d="M55.3518 34.9469H53.6631V38.0897H52.8031V34.9469H51.1301V34.1025H55.3518V34.9469Z" fill="white"/>
      <path d="M58.7604 38.0897V34.1025H59.6204V38.0897H58.7604Z" fill="white"/>
      <path d="M67.4854 38.0897H66.6254L64.3738 35.4472V38.0897H63.5139V34.1025H64.3895L66.6254 36.7607V34.1025H67.4854V38.0897Z" fill="white"/>
      <path d="M73.302 36.5726V35.8065H75.71V36.5726H75.3816C75.2096 37.5734 74.4122 38.2144 73.2708 38.2144C71.973 38.2144 71.0661 37.3075 71.0661 36.0567C71.0661 34.7119 72.0511 33.9771 73.302 33.9771C74.1151 33.9771 74.9751 34.2585 75.2722 35.2748L74.4747 35.5563C74.2715 34.9621 73.7242 34.837 73.2864 34.837C72.5046 34.837 72.0199 35.2748 72.0199 36.0567C72.0199 36.8854 72.5984 37.3232 73.3802 37.3232C73.9275 37.3232 74.3653 37.073 74.5217 36.5726H73.302Z" fill="white"/>
      <path d="M20.796 19.311C20.796 23.8768 17.9346 27.4418 12.7434 27.4418H9.81946C8.34967 27.4418 7.33334 26.5193 7.33334 25.0964C7.33334 23.7204 8.34967 22.751 9.81946 22.751H12.118C14.5885 22.751 15.8394 21.375 15.8394 19.1859C15.8394 17.0594 14.7918 15.6678 12.8372 15.6678H10.1009C8.64676 15.6678 7.72424 14.7453 7.72424 13.4475C7.72424 12.1497 8.64676 11.2272 10.1009 11.2272H11.4456C13.4627 11.2272 14.3383 9.97627 14.3383 8.00612C14.3383 5.98906 13.1187 4.76944 10.6482 4.76944H5.03484V24.7993C5.03484 26.316 4.03411 27.4418 2.51741 27.4418C0.969435 27.4418 0 26.316 0 24.7993V2.72112C0 1.22005 0.969442 0.0786133 2.56433 0.0786133H11.0704C16.2615 0.0786133 19.248 3.31529 19.248 7.91231C19.248 9.85118 18.4506 11.5712 17.4812 12.7751C19.3262 14.1198 20.796 16.4183 20.796 19.311Z" fill="url(#paint0_linear_72_27726)"/>
      <path d="M44.0469 22.9695C44.7975 24.2673 44.516 25.268 43.1713 26.1905C41.7484 27.113 39.4812 27.7854 36.7136 27.7854C29.5053 27.7854 25.2367 23.0946 25.2367 17.0121C25.2367 10.6013 30.0057 6.16064 36.2132 6.16064C42.0299 6.16064 46.064 9.85077 46.064 15.4172C46.064 17.4656 44.7662 18.9823 42.671 18.9823H36.0413C34.6653 18.9823 33.7427 18.263 33.7427 16.887C33.7427 15.4954 34.6653 14.7918 36.0413 14.7918H41.3262C41.3262 12.7747 39.3092 10.5231 36.1351 10.5231C32.6013 10.5231 30.1308 13.2751 30.1308 16.9339C30.1308 20.452 32.398 23.3447 36.5103 23.3447C38.3867 23.3447 39.6532 22.7506 40.7321 22.172C42.2019 21.4528 43.2964 21.6248 44.0469 22.9695Z" fill="url(#paint1_linear_72_27726)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M63.1073 25.1429C63.1073 26.6439 62.1535 27.4414 60.6837 27.4414H58.7136C53.4755 27.4414 50.5828 24.8458 50.5828 18.9041V2.5956C50.5828 1.09453 51.5522 0 53.0533 0C54.57 0 55.5238 1.09453 55.5238 2.5956V18.1379C55.5238 21.3277 56.4464 22.8287 58.7918 22.8287H60.6837C62.1535 22.8287 63.1073 23.6262 63.1073 25.1429ZM57.2438 8.8344C57.2438 7.28643 58.3383 6.45772 59.7612 6.45772H60.6055C62.0284 6.45772 63.0761 7.28643 63.0761 8.8344C63.0761 10.3511 62.0284 11.1798 60.6055 11.1798H59.7612C58.3383 11.1798 57.2438 10.3511 57.2438 8.8344Z" fill="url(#paint2_linear_72_27726)"/>
      <path d="M79.666 25.5651C79.666 26.9411 78.7435 27.7854 77.2737 27.7854C70.9098 27.7854 66.4691 23.0477 66.4691 16.934C66.4691 10.4762 71.1599 6.11377 77.3675 6.11377C83.9034 6.11377 87.9218 9.75698 87.9218 15.8863V20.6554C87.9218 22.1721 86.9212 23.2509 85.4514 23.2509C83.9347 23.2509 82.9809 22.1721 82.9809 20.6554V16.6369C82.9809 12.9937 81.4173 10.7733 77.3988 10.7733C73.8337 10.7733 71.4101 13.3689 71.4101 16.887C71.4101 20.2801 73.8024 23.0946 77.3675 23.1259C78.7435 23.1728 79.7442 24.0015 79.666 25.5651Z" fill="url(#paint3_linear_72_27726)"/>
      <path d="M0.078186 36.0725C0.078186 34.5245 1.17271 33.6802 2.5956 33.6802H3.42432C4.86284 33.6802 5.91046 34.5245 5.91046 36.0725C5.91046 37.5736 4.86284 38.4179 3.42432 38.4179H2.5956C1.17271 38.4179 0.078186 37.5736 0.078186 36.0725Z" fill="url(#paint4_linear_72_27726)"/>
      <path d="M82.1678 36.0725C82.1678 34.5245 83.2623 33.6802 84.6852 33.6802H85.5139C86.9524 33.6802 88 34.5245 88 36.0725C88 37.5736 86.9524 38.4179 85.5139 38.4179H84.6852C83.2623 38.4179 82.1678 37.5736 82.1678 36.0725Z" fill="url(#paint5_linear_72_27726)"/>
      <defs>
        <linearGradient id="paint0_linear_72_27726" x1="-7.61307" y1="27.4457" x2="-7.61307" y2="0.082208" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C7229F"/>
          <stop offset="1" stop-color="#FF006B"/>
        </linearGradient>
        <linearGradient id="paint1_linear_72_27726" x1="24.421" y1="27.7805" x2="24.421" y2="6.15783" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C7229F"/>
          <stop offset="1" stop-color="#FF006B"/>
        </linearGradient>
        <linearGradient id="paint2_linear_72_27726" x1="26.7846" y1="27.4453" x2="26.7846" y2="-0.0020319" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C7229F"/>
          <stop offset="1" stop-color="#FF006B"/>
        </linearGradient>
        <linearGradient id="paint3_linear_72_27726" x1="66.2543" y1="27.7806" x2="66.2543" y2="6.11596" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C7229F"/>
          <stop offset="1" stop-color="#FF006B"/>
        </linearGradient>
        <linearGradient id="paint4_linear_72_27726" x1="0.0786551" y1="33.68" x2="5.90327" y2="33.68" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C7229F"/>
          <stop offset="1" stop-color="#FF006B"/>
        </linearGradient>
        <linearGradient id="paint5_linear_72_27726" x1="82.1683" y1="33.68" x2="87.9928" y2="33.68" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C7229F"/>
          <stop offset="1" stop-color="#FF006B"/>
        </linearGradient>
      </defs>
    </svg>
  </RouterLink>
</template>

<style scoped lang="scss">
.logo {
  position: relative;
  z-index: 1;
  width: fit-content;
}
</style>