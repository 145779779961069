<script setup>
import { watch, onUnmounted } from "vue";
import { useRouter } from 'vue-router';
import Logo from "@/components/base/Logo.vue";
import Nav from "@/components/base/Nav.vue";

const emit = defineEmits(['close']);

const props = defineProps({
  isOpen: Boolean
})

watch(() => props.isOpen, () => {
  if(props.isOpen) document.body.classList.add('no-scroll');
  else document.body.classList.remove('no-scroll');
})

onUnmounted(() => {
  document.body.classList.remove("no-scroll");
})

</script>

<template>
  <Transition name="mob-menu">
    <div v-if="isOpen" class="m-menu">
      <div class="m-menu__top flex align-center justify-between">
        <Logo />
        <svg @click="$emit('close')" class="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M0.957322 20.7324C0.358485 21.3312 0.329969 22.4004 0.97158 23.0277C1.59893 23.655 2.66829 23.6408 3.26712 23.042L11.993 14.3025L20.7332 23.042C21.3463 23.655 22.4014 23.655 23.0288 23.0277C23.6418 22.3862 23.6561 21.3454 23.0288 20.7324L14.3028 11.9929L23.0288 3.26764C23.6561 2.65459 23.6561 1.59958 23.0288 0.972276C22.3871 0.359228 21.3463 0.344971 20.7332 0.958019L11.993 9.69751L3.26712 0.958019C2.66829 0.359228 1.58468 0.330715 0.97158 0.972276C0.344227 1.59958 0.358485 2.66885 0.957322 3.26764L9.69749 11.9929L0.957322 20.7324Z" fill="white" fill-opacity="0.5"/>
        </svg>
      </div>
      <div class="m-menu__content">
        <Nav inMenu @close="$emit('close')" />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.m-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("@/assets/img/menu-bg.jpg") center/cover no-repeat;
  padding: 0 20px;
  &__top {
    height: 90px;
  }
  .close {
    position: absolute;
    top: 33px;
    right: 28px;
    cursor: pointer;
    path {
      transition: all .3s ease;
    }
    &:hover {
      path {
        fill-opacity: 1;
      }
    }
  }
  nav {
    gap: 30px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20%;
    .nav-item p {
      color: rgba(235, 176, 210, 0.80);
      font-size: 34px;
      font-weight: 600;
      line-height: 50px;
      transition: color .3s ease;
      &:hover {
        color: #DD168B;
      }
    }
  }
}

.mob-menu-enter-active,
.mob-menu-leave-active {
  transition: transform 0.5s ease;
}

.mob-menu-enter-from,
.mob-menu-leave-to {
  transform: translateX(100%);
}

.mob-menu-enter-to,
.mob-menu-leave-from {
  transform: translateX(0);
}
</style>