<script setup>
const props = defineProps({
  icon: String,
  title: String,
  text: String,
  move: Boolean
})
</script>

<template>
  <div class="about-block flex flex-col">
    <div class="about-block__top flex align-center" :class="{'move': move}">
      <img :src="require(`@/assets/icons/${props.icon}.svg`)" alt="block icon" />
      <p>{{ title }}</p>
    </div>
    <div class="about-block__content">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.about-block {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 14px 0px rgba(29, 29, 31, 0.03);
  padding: 30px 34px;
  gap: 12px;
  width: 100%;
  &__top {
    gap: 12px;
    &.move {
      gap: 2px;
      svg {
        margin-left: -10px;
      }
    }
    p {
      color: #1D1D1F;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  &__content {
    p {
      color: rgba(29, 29, 31, 0.72);
      font-size: 16px;
      line-height: 28px;
    }
  }
}

@media (max-width: 768px) {
  .about-block {
    gap: 16px;
    &__top {
      p {
        line-height: 22px;
      }
    }
    &__content {
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>