<script setup>
import { onUnmounted } from "vue";

const scrollFunction = () => {
  const item1 = document.querySelector('.moved-text-first');
  const item2 = document.querySelector('.moved-text-second');
  const itemRect = item1.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const scrollPercent = Math.min(Math.max((1.2 * viewportHeight - itemRect.top) / viewportHeight, 0), 1);
  const movementDistance = 500;

  const translateValue = (scrollPercent - 1) * movementDistance;
  const translateValue2 = (scrollPercent - 1) * movementDistance * -1;

  if(window.innerWidth > 768) {
    item1.style.backgroundPositionX = `${Math.max(Math.min(scrollPercent * -150, 0), -10000)}%`;
    item1.style.transform = `translateX(${translateValue}px)`;
    item2.style.backgroundPositionX = `${Math.max(Math.min(scrollPercent * -200, 0), -600)}%`;
    item2.style.transform = `translateX(${translateValue2}px)`;
  } else {
    item1.style.backgroundPositionX = `${Math.max(Math.min(scrollPercent * -150, 0), -10000)}%`;
    item1.style.transform = `translateX(${translateValue * 0.2}px)`;
    item2.style.backgroundPositionX = `${Math.max(Math.min(scrollPercent * -200, 0), -550)}%`;
    item2.style.transform = `translateX(${translateValue2 * 0.5}px)`;
  }
}

window.addEventListener('scroll', scrollFunction);

onUnmounted(() => {
  window.removeEventListener('scroll', scrollFunction);
});
</script>

<template>
  <div class="scroll-mover">
    <img
      src="@/assets/img/spiral_sphere.png"
      alt="spiral_sphere"
      v-motion
      :initial="{opacity: 0, y: 40}"
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 100 }}"
    >
    <p class="moved-text moved-text-first">BETA</p>
    <p class="moved-text moved-text-second">MARKETING</p>
  </div>
</template>

<style scoped lang="scss">
.scroll-mover {
  position: relative;
  margin: 64px 0 46px;
  & > img {
    position: absolute;
    right: 5%;
    top: -31%;
    z-index: 2;
    width: 34.5vw;
    height: auto;
  }
}
.moved-text {
  font-size: 14.3vw;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  display: block;
  text-align: start;
  position: relative;
  translate: none;
  rotate: none;
  scale: none;
  background: linear-gradient(90deg, #1D1D1F 50%, #d2d2d2 0);
  background-size: 200% 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  &-second {
    width: 120%;
  }
}

@media (max-width: 768px) {
  .scroll-mover {
    margin: 57px 0 40px;
    & > img {
      right: -12%;
      top: -100%;
      width: 230px;
    }
  }
  .moved-text {
    font-size: 56px;
    font-weight: 900;
    line-height: 56px;
    text-transform: uppercase;
  }
}
</style>