<template>
  <div class="home">
    <Banner
      title="elevate your digital presence"
      withBtn
      :gap="32"
    >
      <p v-for="(item, i) in content" :key="`block${i}`">
        <span
          v-for="(word, index) in item"
          :key="`${index}span`"
          class="word"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 1, transition: { duration: 800, delay: i*1000 + index*40 }}"
        >
          {{ word }}
        </span>
      </p>
    </Banner>
    <ColoredMarquee />
    <AboutUs ref="about"/>
    <Services ref="services" />
    <Marquee />
    <Commitment ref="commitment" />
    <Expertise ref="expertise" />
    <Contacts ref="contacts" />
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import { useScrollStore } from "@/store/scroll";
import { useRouter, useRoute } from "vue-router";
import Banner from "@/components/sections/Banner.vue";
import AboutUs from "@/components/sections/AboutUs.vue";
import ColoredMarquee from "@/components/additional/ColoredMarquee.vue";
import Services from "@/components/sections/Services.vue";
import Marquee from "@/components/additional/Marquee.vue";
import Commitment from "@/components/sections/Commitment.vue";
import Expertise from "@/components/sections/Expertise.vue";
import Contacts from "@/components/sections/Contacts.vue";
import Footer from "@/components/base/Footer.vue";

const navigationStore = useScrollStore();
const router = useRouter();
const route = useRoute();

const bannerText1 = ref("In today's digital age, making an impact means being seen and heard in the right places at the right times.".split(" "));
const bannerText2 = ref("BETA MARKETING is your trusted partner in navigating the vast realm of digital marketing, ensuring your brand not only stands out but thrives.".split(" "));
const content = ref([bannerText1.value, bannerText2.value]);

const about = ref(null);
const services = ref(null);
const commitment = ref(null);
const expertise = ref(null);
const contacts = ref(null);

const conditionalScroll = () => {
  if (navigationStore.blockClicked) {
    nextTick(() => {
      scrollToBlock(navigationStore.blockIndex);
    });
  }
}

router.afterEach(() => {
  if(route.path === "/") conditionalScroll();
});

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  conditionalScroll();
}, {deep: true})

const getBlockRef = (index) => {
  switch(index) {
    case 'contacts':
      return contacts.value;
    case 'about':
      return about.value;
    case 'services':
      return services.value;
    case 'commitment':
      return commitment.value;
    case 'expertise':
      return expertise.value;
  }
}

const scrollToBlock = (blockIndex) => {
  const blockRef = getBlockRef(blockIndex);
  if (blockRef && blockRef.$el) {
    blockRef.$el.scrollIntoView({ behavior: 'smooth' });
  }
}

const handleScroll = () => {
  if (navigationStore.blockClicked) {
    setTimeout(() => {
      scrollToBlock(navigationStore.blockIndex)
    }, 0);
  }
}

onMounted(() => {
  handleScroll();
})
</script>

<style lang="scss" scoped>
.home {
  overflow-x: hidden;
}
</style>