<script setup>
import {nextTick, ref, watch} from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import BlockArrow from "@/components/icons/BlockArrow.vue";
import TextCard from "@/components/additional/TextCard.vue";
import {useScrollStore} from "@/store/scroll";

const blocks = ref([
  {
    title: "Digital Marketing Consultancy",
    description: "Crafting the perfect roadmap for your online success.",
    arrow: 1,
    text: "We provide expert guidance on developing and implementing effective digital strategies that align with your business goals. From understanding your target audience to optimizing your online presence, our consultants work closely with you to enhance your digital marketing efforts and drive measurable results.",
    background: "card1.jpg",
    block: "digital_marketing"
  },
  {
    title: "Social Media Advertising",
    description: "Engaging audiences where they spend their time the most.",
    arrow: 2,
    text: "We create compelling ad campaigns tailored to your specific audience across platforms like Facebook, Instagram, LinkedIn, and more. Our agency leverages advanced targeting techniques and data-driven insights to ensure your ads reach the right people at the right time, fostering meaningful connections and driving conversions.",
    background: "card2.jpg",
    block: "social_media"
  },
  {
    title: "Website Development & SEO",
    description: "Ensuring your online home is both welcoming and easily discoverable.",
    arrow: 3,
    text: "Our services combine stunning design with powerful functionality to create websites that not only look great but also perform exceptionally well. We build responsive, user-friendly websites optimized for search engines, ensuring your site ranks high in search results.",
    background: "card3.jpg",
    block: "website_development"
  },
  {
    title: "PPC Campaigns",
    description: "Turning every click into a potential conversion.",
    arrow: 4,
    text: "We meticulously research keywords, craft compelling ad copy, and continuously monitor and optimize your campaigns to ensure maximum efficiency and performance. Whether you’re looking to increase sales, generate leads, or boost brand awareness, our PPC services are tailored to meet your specific objectives.",
    background: "card4.jpg",
    block: "ppc"
  },
  {
    title: "Remarketing",
    description: "Reconnecting with audiences who've shown interest, ensuring they choose you.",
    arrow: 5,
    text: "Keep your brand top-of-mind with our Remarketing services. Our agency target previous visitors to your website with personalized ads that remind them of your products or services. By re-engaging these potential customers, we increase the chances of conversion and maximize your marketing efforts. We ensure that your brand stays relevant and visible throughout the customer journey.",
    background: "card5.jpg",
    block: "remarketing"
  },
])

const navigationStore = useScrollStore();

const digital_marketing = ref(null);
const social_media = ref(null);
const website_development = ref(null);
const ppc = ref(null);
const remarketing = ref(null);

blocks.value.map(el => {
  el.titleWords = el.title.split(" ");
  el.descWords = el.description.split(" ");
})

const getBlockRef = (index) => {
  switch(index) {
    case 'digital_marketing':
      return digital_marketing.value;
    case 'website_development':
      return website_development.value;
    case 'ppc':
      return ppc.value;
    case 'social_media':
      return social_media.value;
    case 'remarketing':
      return remarketing.value;
  }
}

const scrollToBlock = (blockIndex) => {
  const blockRef = getBlockRef(blockIndex);
  if (blockRef) {
    blockRef.scrollIntoView({ behavior: 'smooth' });
  }
}

watch(() => navigationStore.blockIndex, (newValue, oldValue) => {
  if (navigationStore.blockClicked) {
    nextTick(() => {
      scrollToBlock(navigationStore.blockIndex);
    });
  }
}, {deep: true})
</script>

<template>
  <section class="services">
    <div class="container">
      <SectionTitle
        isDark
        v-motion
        :initial="{opacity: 0, scale: 0.1, rotate: -15}"
        :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 800, delay: 0 }}"
      >services</SectionTitle>
      <div class="services__content flex flex-col">
        <div
          class="serv-item flex justify-between align-center"
          v-for="(item, index) in blocks"
          :key="index"
          :class="{'right': index % 2 === 1, 'last': index === 4}"
          :ref="(el) => {
            switch (index) {
              case 0:
                digital_marketing = el;
                break;
              case 1:
                social_media = el;
                break;
              case 2:
                website_development = el;
                break;
              case 3:
                ppc = el;
                break;
              case 4:
                remarketing = el;
                break;
            }
          }"
        >
          <div class="serv-item__left flex flex-col">
            <h4>
              <span
                v-for="(word, index) in item.titleWords"
                :key="`${index}span`"
                class="word"
                v-motion
                :initial="{opacity: 0}"
                :visibleOnce="{opacity: 1, transition: { duration: 800, delay: index*50 }}"
              >
                {{ word }}
              </span>
            </h4>
            <p>
              <span
                v-for="(word, index) in item.descWords"
                :key="`${index}span`"
                class="word"
                v-motion
                :initial="{opacity: 0}"
                :visibleOnce="{opacity: 1, transition: { duration: 800, delay: index*50+100 }}"
              >
                {{ word }}
              </span>
            </p>
            <BlockArrow :order="item.arrow" />
          </div>
          <div class="serv-item__right">
            <TextCard
              :text="item.text"
              :background="item.background"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.services {
  padding: 40px 0;
  &__content {
    margin-top: 64px;
    gap: 60px;
  }
}
.serv-item {
  gap: 30px;
  &.right {
    flex-direction: row-reverse;
    .serv-item__left svg {
      margin: -10px auto 0 -40px;
    }
  }
  &.last {
    .serv-item__left svg {
      margin: 30px -40px 0 auto;
    }
  }
  &__left {
    color: #1D1D1F;
    max-width: 440px;
    width: 100%;
    h4 {
      font-size: 56px;
      font-weight: 700;
      line-height: 62px;
      span {
        margin-right: 18px;
        display: inline-block;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      margin: 14px 0 0;
      width: 88%;
      span {
        margin-right: 4px;
        display: inline-block;
      }
    }
    svg {
      margin: -20px -40px 0 auto;
    }
  }
}

@media (max-width: 1280px) {
  .serv-item {
    &__left {
      h4 {
        font-size: 48px;
        line-height: 110%;
        span {
          margin-right: 14px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .services {
    padding: 40px 0 0;
  }
  .services__content {
    gap: 40px;
    margin-top: 35px;
  }
  .serv-item {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 20px;
    &.right {
      flex-direction: column;
    }
    &__left {
      max-width: none;
      svg {
        display: none;
      }
    }
  }
}
</style>