<script setup>
import { onUnmounted, ref } from "vue";
import AboutBlock from "@/components/additional/AboutBlock.vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";

const scrollFunction = () => {
  const scrollValue = window.scrollY;

  const columnFirst = document.querySelector('.about-first');
  const columnSecond = document.querySelector('.about-second');

  if(window.innerWidth > 1024) {
    columnFirst.style.transform = `translateY(${scrollValue * -0.15 - 100}px)`;
    columnSecond.style.transform = `translateY(${scrollValue * 0.15 - 150}px)`;
  } else if(window.innerWidth <= 1024 && window.innerWidth > 768) {
    columnFirst.style.transform = `translateX(${scrollValue * -0.25 - 250}px)`;
    columnSecond.style.transform = `translateX(${scrollValue * 0.25 - 600}px)`;
  } else {
    const columnThird = document.querySelector('.about-third');
    columnFirst.style.transform = `translateX(${Math.max(scrollValue * -0.1 + 150, 0)}px)`;
    columnSecond.style.transform = `translateX(${Math.min(scrollValue * 0.1 - 50, 140)}px)`;
    if(!!columnThird) {
      columnThird.style.transform = `translateX(${Math.max(scrollValue * -0.09 + 170, 20)}px)`;
    }
  }
}

window.addEventListener('scroll', scrollFunction);

onUnmounted(() => {
  window.removeEventListener('scroll', scrollFunction);
});
</script>

<template>
  <section class="about flex justify-between">
    <div class="about-left flex align-center">
      <SectionTitle
        v-motion
        :initial="{opacity: 0, scale: 0.1, rotate: -15}"
        :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 800, delay: 0 }}"
      >about us</SectionTitle>
      <h2>Why <br/> choose us?</h2>
    </div>
    <div
      class="about-right flex"
      v-motion
      :initial="{opacity: 0}"
      :visibleOnce="{opacity: 1, transition: { duration: 1500, delay: 200 }}"
    >
      <div class="column about-first flex flex-col">
        <div class="rect" />
        <div class="rect" />
        <AboutBlock
          icon="yellow"
          title="Industry Expertise"
          move
          text="Years of experience has cemented our position as leaders in digital strategies and online market acquisition."
        />
        <div class="rect" />
      </div>
      <div class="column about-second flex flex-col">
        <div class="rect" />
        <AboutBlock
          icon="green"
          title="Data-Driven Insights"
          text="With our robust digital marketing analytics, every decision is informed, ensuring optimal results for your campaigns.s of experience has cemented our position as leaders in digital strategies and online market acquisition."
        />
        <AboutBlock
          class="block-desk"
          icon="pink"
          title="Custom Solutions"
          text="No two businesses are the same. Our tailored strategies ensure that your unique needs are met with precision."
        />
        <div class="rect" />
        <div class="rect" />
      </div>
      <div class="column about-third flex flex-col">
        <div class="rect" />
        <AboutBlock
          icon="pink"
          title="Custom Solutions"
          text="No two businesses are the same. Our tailored strategies ensure that your unique needs are met with precision."
        />
        <div class="rect" />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.about {
  width: 98%;
  max-width: 1400px;
  max-height: 640px;
  margin: 20px auto;
  border-radius: 40px;
  background: url('@/assets/img/about_bg.jpg') center/cover no-repeat;
  gap: 20px;
  .about-left {
    margin: 90px 0 90px 60px;
    position: relative;
    min-width: 230px;
    .section-title {
      top: 0;
      left: 0;
      position: absolute;
    }
    h2 {
      color: #FFF;
      font-size: 46px;
      line-height: 52px;
      font-weight: 400;
    }
  }
  .about-right {
    padding: 0 60px 0 0;
    overflow: hidden;
    gap: 22px;
    align-items: flex-end;
    .column {
      gap: 22px;
    }
  }
  .rect {
    background: rgba(235, 235, 237, 0.13);
    border-radius: 20px;
    width: 100%;
    height: 260px;
    min-height: 260px;
  }
  .column {
    max-width: 440px;
    max-height: 800px;
    width: 52%;
  }
  .about-second {
    margin-top: -250px;
  }
  .about-third {
    display: none;
  }
}

@media (max-width: 1280px) {
  .about {
    .about-right {
      padding: 0 30px 0 0;
    }
    .about-left {
      margin: 70px 0 70px 30px;
    }
    .about-block {
      padding: 30px 26px;
    }
  }
}

@media (max-width: 1024px) {
  .about {
    flex-direction: column;
    max-height: none;
    margin: 0 auto;
    .about-left {
      flex-direction: column;
      align-items: flex-start;
      gap: 22px;
      .section-title {
        position: relative;
      }
    }
    .about-right {
      flex-direction: column;
      padding: 0 0 48px 0;
    }
    .column {
      width: 100%;
      max-width: none;
      flex-direction: row;
      .rect, .about-block {
        min-width: 350px;
        flex: 1 0 40%;
        min-height: 312px;
      }
    }
    .about-second {
      margin-top: 0;
      .block-desk {
        display: none;
      }
    }
    .about-third {
      display: flex;
    }
  }
}

@media (max-width: 768px) {
  .about {
    margin: 0 20px;
    width: calc(100% - 40px);
    .about-left {
      margin: 50px 26px 20px;
      h2 {
        display: none;
      }
    }
    .column {
      width: auto;
      .rect {
        min-height: 0 !important;
        min-width: 200px;
        height: auto;
      }
    }
    .about-block {
      min-height: 0 !important;
      min-width: 0 !important;
      width: 100%;
    }
    .about-first .rect:nth-child(2) {
      display: none;
    }
    .about-second .rect:nth-child(4) {
      display: none;
    }
  }
}
</style>