<script setup>

</script>

<template>
  <div class="marquee">
    <div class="marquee__content flex">
      <p>elevate your digital presence</p>
      <p>elevate your digital presence</p>
      <p>elevate your digital presence</p>
      <p>elevate your digital presence</p>
      <p>elevate your digital presence</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 40px 0 20px;

  .marquee__content {
    gap: 40px;
    animation: scroll-left 100s linear infinite;
    color: #ECECED;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 86px;
    font-weight: 900;
    line-height: 86px;
    text-transform: uppercase;
  }
}

@keyframes scroll-left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-400%);
  }
}

@keyframes scroll-left2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-600%);
  }
}

@media (max-width: 768px) {
  .marquee {
    padding: 40px 0 0;
    .marquee__content {
      font-size: 48px !important;
      line-height: 48px !important;
      animation: scroll-left2 100s linear infinite;
      p:last-child {
        display: none;
      }
    }
  }
}
</style>