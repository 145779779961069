<script setup>
const props = defineProps({
  image: String,
  title: String,
  text: String,
})
</script>

<template>
  <div class="slide-card flex flex-col">
    <img :src="require(`@/assets/img/${props.image}`)" alt="slide block image" />
    <p class="slide-card__title">{{ title }}</p>
    <p class="slide-card__text">{{ text }}</p>
  </div>
</template>

<style scoped lang="scss">
.slide-card {
  color: #1D1D1F;
  img {
    width: 352px;
    height: 470px;
    border-radius: 16px;
    overflow: hidden;
  }
  &__title {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin: 23px 0 14px;
  }
  &__text {
    font-size: 16px;
    line-height: 28px;
    opacity: 0.6;
  }
}

@media (max-width: 768px) {
  .slide-card {
    &__title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
    &__text {
      opacity: 1;
    }
    img {
      width: 100%;
    }
  }
}
</style>