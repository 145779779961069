<script setup>
const props = defineProps({
  text: String,
  background: String
})
</script>

<template>
  <div class="text-card">
    <img :src="require(`@/assets/img/${props.background}`)" alt="text card background" />
    <div class="text-card__overlay overlay" />
    <div class="text-card__overlay2 overlay" />
    <div class="text-card__overlay3 overlay" />
    <p>{{ text }}</p>
  </div>
</template>

<style scoped lang="scss">
.text-card {
  width: 100%;
  max-width: 738px;
  padding: 150px 84px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-blend-mode: normal, normal, darken, normal, normal, normal;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &__overlay {
    background: linear-gradient(180deg, rgba(208, 29, 151, 0.49) 0%, rgba(0, 0, 0, 0.9) 100%);
    z-index: 2;
  }
  &__overlay2 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
    z-index: 3;
  }
  &__overlay3 {
    background: linear-gradient(0deg, rgba(210, 28, 150, 0.30) 0%, rgba(210, 28, 150, 0.30) 100%);
    z-index: 2;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  p {
    position: relative;
    z-index: 4;
    color: #FFF;
    font-size: 20px;
    line-height: 32px;
    opacity: 0;
    transition: opacity .6s ease;
  }
  &:hover {
    p {
      opacity: 1;
    }
    .overlay {
      opacity: 1;
    }
  }
}

@media (max-width: 1280px) {
  .text-card {
    padding: 80px 60px;
  }
}

@media (max-width: 1024px) {
  .text-card {
    padding: 40px;
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}

@media (max-width: 768px) {
  .text-card {
    padding: 90px 36px;
    img {
      width: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    p {
      opacity: 1;
    }
    &__overlay2, &__overlay3 {
      opacity: 1 !important;
    }
  }
}
</style>