<script setup>
import { ref, onUnmounted, watch } from "vue";
import Form from "@/components/ui/Form.vue";
import ResponseModal from "@/components/ui/ResponseModal.vue";

const props = defineProps({
  isOpen: Boolean,
  fromChild: Boolean,
  isSuccessChild: Boolean,
  isSentChild: Boolean,
})

const isSuccess = ref(false);
const isSent = ref(false);

watch(() => props.isOpen, () => {
  if(props.isOpen) document.body.classList.add('no-scroll');
  else {
    isSent.value = false;
    document.body.classList.remove('no-scroll');
  }
})

onUnmounted(() => {
  isSent.value = false;
  document.body.classList.remove('no-scroll');
})

const getResponse = (val) => {
  isSent.value = true;
  isSuccess.value = val;
}
</script>

<template>
  <Transition name="mob-modal">
    <div v-if="isOpen" class="m-modal flex justify-center align-center">
      <template v-if="!fromChild">
        <template v-if="!isSent">
          <svg class="close" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M0.957078 20.7324C0.358241 21.3312 0.329725 22.4004 0.971336 23.0277C1.59869 23.655 2.66804 23.6408 3.26688 23.042L11.9928 14.3025L20.733 23.042C21.3461 23.655 22.4012 23.655 23.0285 23.0277C23.6416 22.3862 23.6559 21.3454 23.0285 20.7324L14.3026 11.9929L23.0285 3.26764C23.6559 2.65459 23.6559 1.59958 23.0285 0.972276C22.3869 0.359228 21.3461 0.344971 20.733 0.958019L11.9928 9.69751L3.26688 0.958019C2.66804 0.359228 1.58443 0.330715 0.971336 0.972276C0.343983 1.59958 0.358241 2.66885 0.957078 3.26764L9.69725 11.9929L0.957078 20.7324Z" fill="white" fill-opacity="0.5"/>
          </svg>
          <Form inModal @response="getResponse" />
        </template>
        <ResponseModal v-else :isSuccess="isSuccess" @close="$emit('close')" />
      </template>
      <ResponseModal v-if="isSentChild" :isSuccess="isSuccessChild" @close="$emit('close')"/>
    </div>
  </Transition>
</template>

<style lang="scss">
.m-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("@/assets/img/desk-bg.jpg") center/cover no-repeat;
  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 99999;
    path {
      transition: all .3s ease;
    }
    &:hover {
      path {
        fill-opacity: 1;
      }
    }
  }
  .f-form {
    position: relative;
    max-width: 660px;
    margin: 0;
    width: calc(100% - 40px);
    &__top h4 {
      color: #EEE0E8;
    }
    .bottom {
      margin-top: 0;
    }
  }
  form button {
    max-width: 290px !important;
  }
}

@media (max-width: 768px) {
  .m-modal {
    background: url("@/assets/img/menu-bg.jpg") center/cover no-repeat;
    .close {
      top: 33px;
      right: 28px;
    }
  }
}

.mob-modal-enter-active,
.mob-modal-leave-active {
  transition: transform 0.4s ease;
}

.mob-modal-enter-from,
.mob-modal-leave-to {
  transform: translateY(100%);
}

.mob-modal-enter-to,
.mob-modal-leave-from {
  transform: translateY(0);
}

</style>