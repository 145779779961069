<script setup>
import { ref, computed } from "vue";
import Header from "@/components/base/Header.vue";
import Button from "@/components/ui/Button.vue";
import Modal from "@/components/base/Modal.vue";

const props = defineProps({
  title: String,
  withBtn: Boolean,
  gap: Number
})

const gapStyle = computed(() => {
  return `gap: ${props.gap}px`;
})

const isModal = ref(false);

const titleWords = ref(props.title.split(" "));
</script>

<template>
  <div class="wrapper">
    <Header />
    <section class="banner flex flex-col justify-end align-center">
      <video id="vid" playsinline="" defaultmuted="" autoplay="" muted="" loop="">
        <source width="100%" src="@/assets/video/sphere.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="overlay" />
      <div class="banner__body flex flex-col align-center" :style="gapStyle">
        <h1>
          <span
            v-for="(word, index) in titleWords"
            :key="`${index}span`"
            class="word"
            v-motion
            :initial="{opacity: 0}"
            :visibleOnce="{opacity: 1, transition: { duration: 1200, delay: index*40 }}"
          >
          {{ word }}
        </span>
        </h1>
        <div class="banner__text">
          <slot />
        </div>
        <Button
          v-if="withBtn"
          @click="isModal = true"
          v-motion
          :initial="{opacity: 0, x: -20}"
          :visibleOnce="{opacity: 1, x: 0, transition: { duration: 1000, delay: 1000 }}"
        >Get In Touch</Button>
      </div>
      <div class="scroll-down flex">
        Scroll down
        <svg class="arrow arrow-first" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.0001 13.2059C10.2199 13.1997 10.4208 13.118 10.584 12.9423L15.3552 8.05805C15.4933 7.91994 15.5686 7.74416 15.5686 7.53699C15.5686 7.12265 15.2422 6.78992 14.8278 6.78992C14.627 6.78992 14.4323 6.87153 14.2879 7.01592L10.0064 11.4167L5.71233 7.01592C5.56794 6.87781 5.3796 6.78992 5.17243 6.78992C4.75809 6.78992 4.43164 7.12265 4.43164 7.53699C4.43164 7.74416 4.50698 7.91994 4.64509 8.05805L9.42257 12.9423C9.59208 13.118 9.78041 13.2059 10.0001 13.2059Z" fill="#F5F5F7" fill-opacity="0.4"/>
        </svg>
      </div>
    </section>
    <Modal :isOpen="isModal" @close="isModal = false" />
  </div>
</template>

<style lang="scss">
.wrapper {
  background: #0B0B0B;
  .banner {
    height: calc(100vh - 95px);
    padding-bottom: 42px;
    &__body {
      position: relative;
      z-index: 3;
      max-width: 1033px;
      margin: auto;
      h1 {
        color: #EEE0E8;
        text-align: center;
        font-size: 78px;
        font-weight: 400;
        line-height: 90px;
        word-spacing: normal;
        width: 90%;
        margin: 0 auto;
        span {
          margin-right: 24px;
          display: inline-block;
        }
      }
      .banner__text {
        color: rgba(238, 224, 232, 0.80);
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        width: 82%;
        margin: 0 auto;
        white-space: break-spaces;
        p {
          word-spacing: normal;
          span {
            margin-right: 4px;
            display: inline-block;
          }
        }
      }
    }
  }
  .overlay {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) -24.11%, #000 107.22%);
  }
  video {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    height: 58vh;
    width: 80%;
    object-fit: cover;
    overflow: visible;
  }
  .scroll-down {
    gap: 6px;
    color: rgba(238, 224, 232, 0.56);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    width: 90px;
    position: relative;
  }
}

.arrow {
  display: block;
  position: absolute;
  right: 0;
  top: 70%;
  height: 20px;
  transform-origin: 50% 50%;
  transform: translate3d(0%, -50%, 0);
}

.arrow-first {
  animation: arrow-movement 1.6s ease-in-out infinite;
}

.arrow:before {
  transform: rotate(45deg) translateX(-23%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-45deg) translateX(23%);
  transform-origin: top right;
}

@keyframes arrow-movement {
  0% {
    opacity: 0;
    top: 35%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .wrapper {
    height: 100%;
    position: relative;
    video {
      height: 100vh;
      width: 100%;
    }
    .overlay {
      height: 100vh;
    }
    .banner {
      padding-bottom: 32px;
      &__body {
        gap: 22px !important;
        h1 {
          font-size: 35px;
          font-weight: 500;
          line-height: 50px;
          width: 290px;
          margin: 0 auto;
          span {
            margin-right: 10px;
          }
        }
        .banner__text {
          width: calc(100% - 40px);
        }
        button {
          margin-top: 18px;
        }
      }
    }
  }
}
</style>