<script setup>
import { ref } from "vue";
import {useScrollStore} from "@/store/scroll";
import {useRouter} from "vue-router";

const props = defineProps({
  isFooter: Boolean,
  inMenu: Boolean,
})

const emit = defineEmits(["close"]);

const navs = ref([
  {
    label: "About us",
    block: "about",
    items: []
  },
  {
    label: "Services",
    block: "services",
    items: [
      {
        label: "Digital Marketing Consultancy",
        block: "digital_marketing"
      },
      {
        label: "Social Media Advertising",
        block: "social_media"
      },
      {
        label: "Website Development & SEO",
        block: "website_development"
      },
      {
        label: "PPC Campaigns",
        block: "ppc"
      },
      {
        label: "Remarketing",
        block: "remarketing"
      },
    ]
  },
  {
    label: "Our commitment",
    block: "commitment",
    items: []
  },
  {
    label: "Our Expertise",
    block: "expertise",
    items: []
  },
  {
    label: "Contacts",
    block: "contacts",
    items: []
  }
])

const activeIndex = ref(-1);
const scrollStore = useScrollStore();
const router = useRouter();

const handleActiveNav = (block, index, parentIdx = 0) => {
  if(!props.isFooter && !props.inMenu && index !== 1) parentIdx = 0;
  if(index === 1 && parentIdx === 1) parentIdx = 0;
  if(props.isFooter || props.inMenu) parentIdx = 0;

  if(parentIdx === 0) {
    if(router.currentRoute.value.name !== 'home') router.push('/');
    document.body.classList.remove('no-scroll');
    scrollStore.setBlockClicked(true);
    scrollStore.setBlockIndex(block);
    emit('close');
    setTimeout(() => {
      scrollStore.setBlockClicked(false);
      scrollStore.setBlockIndex("");
    }, 500);
  }
}
</script>

<template>
  <nav
    class="flex align-center"
    :class="{'in-footer': isFooter, 'in-menu': inMenu}"
  >
    <div
      v-for="(item, index) in navs"
      :key="index"
      class="nav-block"
      @mouseenter="activeIndex = index"
      @mouseleave="activeIndex = -1"
      v-motion
      :initial="isFooter ? {opacity: 0, y: -10} : {opacity: 1}"
      :visibleOnce="isFooter ? {opacity: 1, y: 0, transition: { duration: 800, delay: (index === 0 || index === 1) ? 100 : (index === 2 || index === 3) ? 300 : 500}} : {opacity: 1}"
    >
      <div
        class="nav-item flex align-center"
        @click="handleActiveNav(item.block, index, -1)"
        :class="{'active': activeIndex === 1 && index === 1}"
      >
        <div v-if="!isFooter && !inMenu" class="nav-ellipse" />
        <p>{{ item.label }}</p>
        <svg v-if="item.items.length > 0 && !isFooter && !inMenu" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <path opacity="0.82" d="M10.5031 13.415C10.7228 13.4088 10.9237 13.3271 11.0869 13.1514L15.8581 8.26716C15.9962 8.12905 16.0716 7.95326 16.0716 7.74609C16.0716 7.33175 15.7451 6.99902 15.3308 6.99902C15.1299 6.99902 14.9353 7.08064 14.7909 7.22503L10.5093 11.6258L6.21526 7.22503C6.07087 7.08691 5.88253 6.99902 5.67536 6.99902C5.26102 6.99902 4.93457 7.33175 4.93457 7.74609C4.93457 7.95326 5.00991 8.12905 5.14802 8.26716L9.9255 13.1514C10.095 13.3271 10.2833 13.415 10.5031 13.415Z" fill="#EEE0E8" fill-opacity="0.8"/>
        </svg>
      </div>
      <div v-if="activeIndex === 1 && item.items.length > 0 && !isFooter && !inMenu" class="nav-item--hidden">
        <div class="hidden-menu flex flex-col">
          <p
            v-for="(i, idx) in item.items"
            :key="idx"
            @click="handleActiveNav(i.block, idx, index)"
          >{{ i.label }}</p>
        </div>
      </div>
    </div>
    <RouterLink
      v-if="isFooter"
      class="link"
      to="/privacy"
      v-motion
      :initial="{opacity: 0, y: -15}"
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 500 }}"
    >Privacy Policy</RouterLink>
  </nav>
</template>

<style scoped lang="scss">
nav {
  gap: 28px;
  .nav-block {
    position: relative;
    z-index: 4;
  }
  .nav-item {
    cursor: pointer;
    gap: 6px;
    p {
      color: rgba(238, 224, 232, 0.80);
      font-size: 14px;
      line-height: 20px;
      transition: color .3s ease;
      white-space: nowrap;
    }
    svg {
      transition: transform .3s ease;
    }
    svg path {
      transition: fill .3s ease;
    }
    &.active {
      svg {
        transform: rotate(-180deg);
      }
    }
    &:hover, &.active {
      .nav-ellipse {
        background: linear-gradient(90deg, #C7229F 0.01%, #FF006B 99.88%), #D9D9D9;
      }
      p {
        color: #DD168B;
      }
      svg path {
        fill: #DD168B;
      }
    }
    &:active, &:focus {
      p {
        color: #94115E;
      }
    }
  }
  .nav-ellipse {
    width: 6px;
    height: 5px;
    border-radius: 100%;
    border: 1px solid #C7229F;
    transition: background .3s ease-out;
  }
  .nav-item--hidden {
    position: absolute;
    top: 20px;
    left: 0;
    width: 40vw;
    max-width: 240px;
    z-index: 4;
    .hidden-menu {
      border-radius: 10px;
      background: rgba(27, 24, 26, 0.79);
      //background: rgba(238, 224, 232, 0.13);
      gap: 12px;
      padding: 20px 16px;
      margin-top: 16px;
      position: relative;
      z-index: 4;
    }
    p {
      color: rgba(238, 224, 232, 0.80);
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      transition: color .3s ease;
      &:hover {
        color: #DD168B;
      }
    }
  }
}

@media (max-width: 1024px) {
  nav {
    gap: 18px;
  }
}
</style>