<script setup>
import { ref } from "vue";
import Logo from "@/components/base/Logo.vue";
import Nav from "@/components/base/Nav.vue";
import Burger from "@/components/base/Burger.vue";
import Menu from "@/components/base/Menu.vue";

const isMenu = ref(false);
</script>

<template>
  <header>
    <div
      class="container flex align-center justify-between"
      v-motion
      :initial="{opacity: 0, y: -10}"
      :visibleOnce="{opacity: 1, y: 0, transition: { duration: 1000, delay: 0 }}"
    >
      <Logo />
      <Nav />
      <Burger class="header-burger" @click="isMenu = true" />
    </div>
  </header>
  <Menu :isOpen="isMenu" @close="isMenu = false" />
</template>

<style lang="scss">
header {
  padding: 30px 0;
  position: relative;
  z-index: 4;
  & > .flex {
    gap: 30px;
  }
  .header-burger {
    display: none;
  }
}

@media (max-width: 768px) {
  header {
    padding: 25px 0;
    nav {
      display: none !important;
    }
    .header-burger {
      display: flex;
    }
  }
}
</style>