<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import AboutBlock from "@/components/additional/AboutBlock.vue";

const blocks = ref([
  {
    icon: "people",
    title: "Client-Centric Approach",
    move: false,
    text: "Your goals become our mission. We dive deep into understanding your brand to provide solutions that resonate."
  },
  {
    icon: "rocket",
    title: "Innovative Strategies",
    move: true,
    text: "While we respect tried-and-true methods, we're not afraid to think outside the box, offering you the latest and most effective strategies."
  },
  {
    icon: "layers",
    title: "Transparent Reporting",
    move: true,
    text: "As we embark on this journey together, you'll always be in the loop. Regular, clear, and insightful reports ensure you know the progress every step of the way.."
  }
])

const textWords = ref("we understand the challenges of entering the digital space, especially in today's rapidly evolving environment. As a new player, our fresh perspective is paired with an unwavering commitment to excellence.".split(" "));
</script>

<template>
  <section class="commitment">
    <div class="commitment__bg" />
    <SectionTitle
      v-motion
      :initial="{opacity: 0, scale: 0.1, rotate: -15}"
      :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 800, delay: 0 }}"
    >our commitment</SectionTitle>
    <p
      class="commitment__title"
      v-motion
      :initial="{opacity: 0, scale: 1.3, x: -10}"
      :visibleOnce="{opacity: 1, scale: 1, x: 0, transition: { duration: 700, delay: 300 }}"
    >at <span>BETA MARKETING</span></p>
    <p class="commitment__text">
      <span
        v-for="(word, index) in textWords"
        :key="`${index}span`"
        class="word"
        v-motion
        :initial="{opacity: 0}"
        :visibleOnce="{opacity: 1, transition: { duration: 500, delay: index*40+150 }}"
      >
        {{ word }}
      </span>
    </p>
    <div
      class="commitment__content flex"
      v-motion
      :initial="{opacity: 0}"
      :visibleOnce="{opacity: 1, transition: { duration: 1000, delay: 1300 }}"
    >
      <AboutBlock
        v-for="(item, index) in blocks"
        :key="index"
        :icon="item.icon"
        :title="item.title"
        :text="item.text"
        :move="item.move"
      />
    </div>
  </section>
</template>

<style lang="scss">
.commitment {
  width: 98%;
  max-width: 1400px;
  margin: 60px auto 20px;
  padding: 60px 60px 126px;
  position: relative;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('@/assets/img/about_bg.jpg') center/cover no-repeat;
    transform: scaleX(-1);
    z-index: -1;
    border-radius: 40px;
  }
  &__title {
    color: #FFF;
    font-size: 22px;
    line-height: 50px;
    margin-top: 70px;
    span {
      font-size: 34px;
      font-weight: 600;
      line-height: 50px;
      background: linear-gradient(90deg, #C7229F 0.01%, #FF006B 99.88%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__text {
    color: rgba(255, 255, 255, 0.76);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 22px;
    line-height: 36px;
    margin-top: 6px;
    width: 100%;
    max-width: 1030px;
    span {
      margin-right: 4px;
      display: inline-block;
    }
  }
  &__content {
    margin-top: 40px;
    gap: 37px;
  }
  .about-block {
    min-height: 250px;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .commitment {
    padding: 40px 40px 80px;
    &__content {
      gap: 20px;
    }
    .about-block {
      padding: 30px 24px;
      &__top p {
        font-size: 16px;
        line-height: 120%;
      }
    }
  }
}

@media (max-width: 768px) {
  .commitment {
    width: calc(100% - 40px);
    margin: 40px 20px 0;
    padding: 50px 26px 48px;
    &__content {
      flex-direction: column;
    }
    &__title {
      margin-top: 40px;
      font-size: 16px;
      line-height: 28px;
      span {
        font-size: 29px;
        line-height: 40px;
      }
    }
    &__text {
      color: #EEE0E8;
      font-size: 16px;
      line-height: 28px;
      margin-top: 8px;
    }
    .about-block {
      min-height: 0;
      &__top p {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
</style>