<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import Form from "@/components/ui/Form.vue";
import Modal from "@/components/base/Modal.vue";

const isSuccess = ref(false);
const isSent = ref(false);

const getResponse = (val) => {
  isSent.value = true;
  isSuccess.value = val;
}
</script>

<template>
  <section class="c-contacts">
    <div class="container relative">
      <img
        class="c-contacts__first-img" src="@/assets/img/form1.png"
        alt="contacts figure"
        v-motion
        :initial="{opacity: 0, x: 30}"
        :visibleOnce="{opacity: 1, x: 0, transition: { duration: 800, delay: 0 }}"
      />
      <img
        class="c-contacts__second-img"
        src="@/assets/img/form2.png"
        alt="contacts figure"
        v-motion
        :initial="{opacity: 0, x: -30}"
        :visibleOnce="{opacity: 1, x: 0, transition: { duration: 800, delay: 0 }}"
      />
      <SectionTitle
        isDark
        v-motion
        :initial="{opacity: 0, scale: 0.1, rotate: -15}"
        :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 800, delay: 0 }}"
      >Contact</SectionTitle>
      <Form @response="getResponse"/>
    </div>
    <Modal :isOpen="isSent" fromChild :isSuccessChild="isSuccess" :isSentChild="isSent" @close="isSent = false" />
  </section>
</template>

<style scoped lang="scss">
.c-contacts {
  padding: 60px 0 140px;
  img {
    position: absolute;
  }
  &__first-img {
    top: -20%;
    right: -30px;
  }

  &__second-img {
    bottom: -25%;
    left: -35px;
  }
}

@media (max-width: 1280px) {
  .c-contacts {
    &__first-img {
      right: -100px;
    }
    &__second-img {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .c-contacts {
    padding: 40px 0 80px;
    &__first-img {
      width: 200px;
      right: -10px;
      top: -15%;
    }
  }
}
</style>