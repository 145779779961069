<script setup>
import { ref } from "vue";
import SectionTitle from "@/components/additional/SectionTitle.vue";
import ScrollMover from "@/components/additional/ScrollMover.vue";
import Slider from "@/components/additional/Slider.vue";

const textWords = ref("May be new to the scene, but our team is backed by industry veterans with a combined experience spanning decades. We don't just offer services; we offer integrated solutions that encompass every aspect of digital marketing, ensuring cohesion and synergy in your campaigns. Armed with the latest tools and technologies, we ensure your campaigns are efficient, effective, and ahead of the curve. The digital world is ever-evolving, and so are we. Constant upskilling and staying abreast of industry changes mean you always get the best, most updated solutions.".split(" "));
const digitalWords = ref("In today's interconnected world, a digital presence is no longer optional; it's essential. Here's why:".split(" "));
</script>

<template>
  <section class="expertise">
    <div class="container relative">
      <SectionTitle
        isDark
        v-motion
        :initial="{opacity: 0, scale: 0.1, rotate: -15}"
        :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 800, delay: 0 }}"
      >Our Expertise</SectionTitle>
      <ScrollMover />
      <p class="expertise__text">
        <span
          v-for="(word, index) in textWords"
          :key="`${index}span`"
          class="word"
          v-motion
          :initial="{opacity: 0}"
          :visibleOnce="{opacity: 1, transition: { duration: 500, delay: index*40+100 }}"
        >
          {{ word }}
        </span>
      </p>

      <div class="digital relative">
        <img
          class="figure"
          src="@/assets/img/spiral.png"
          alt="spiral"
          v-motion
          :initial="{opacity: 0, y: -40}"
          :visibleOnce="{opacity: 1, y: 0, transition: { duration: 800, delay: 100 }}"
        />
        <p
          class="digital__title"
          v-motion
          :initial="{opacity: 0, scale: 0.1, rotate: -15}"
          :visibleOnce="{opacity: 1, scale: 1, rotate: 0, transition: { duration: 800, delay: 0 }}"
        >Why <span>digital?</span></p>
        <p class="digital__text">
          <span
            v-for="(word, index) in digitalWords"
            :key="`${index}span`"
            class="word"
            v-motion
            :initial="{opacity: 0}"
            :visibleOnce="{opacity: 1, transition: { duration: 500, delay: index*40+150 }}"
          >
            {{ word }}
          </span>
        </p>
      </div>
      <Slider />
    </div>
  </section>
</template>

<style scoped lang="scss">
.expertise {
  padding: 60px 0 20px;
  position: relative;
  z-index: 5;
  &__text {
    color: #1D1D1F;
    font-size: 16px;
    line-height: 28px;
    max-width: 935px;
    span {
      margin-right: 4px;
      display: inline-block;
    }
  }
  .figure {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20.3vw;
    height: auto;
  }
  .digital {
    margin-top: 120px;
    &__title {
      color: #1D1D1F;
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      width: fit-content;
      span {
        color: #DD168B;
      }
    }
    &__text {
      color: #1D1D1F;
      font-size: 16px;
      line-height: 28px;
      margin: 16px 0 40px;
      span {
        margin-right: 4px;
        display: inline-block;
      }
    }
  }
}

@media (max-width: 768px) {
  .expertise {
    padding: 40px 0 20px;
    .digital {
      margin-top: 80px;
      &__text {
        margin: 16px 0 32px;
      }
    }
    .figure {
      width: 180px;
      bottom: 48%;
      right: -40px;
    }
  }
}
</style>